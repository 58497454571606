export default defineNuxtPlugin(({ $cms, $readItems, $readUsers }) => {
  const historyHelpers = {
    defaultRotationTypes: ['Station', 'Ambulanz', 'Intensiv', 'Funktion', 'ZNA', 'OP'],
    facilityFields: ['id', 'name', 'parent_facility.id', 'parent_facility.name'],
    unitFields: [
      'id',
      'short_name',
      'long_name',
      'specialities.id',
      'specialities.speciality_id.id',
      'specialities.speciality_id.name',
      'type',
      'minimum_vacancies',
      'maximum_vacancies',
      'rotation_types',
    ],
    userFields: [
      'id',
      'email',
      'gender',
      'title',
      'first_name',
      'last_name',
      'facilities.id',
      'facilities.facility_id.id',
    ],
    timeSpanFields: ['id', 'text', 'type', 'percentage', 'start', 'end'],
    entryFields: [
      'id',
      'start',
      'end',
      'specialities.id',
      'specialities.speciality_id.id',
      'specialities.speciality_id.name',
      'type',
      'rotation_type',
      'percentage',
      'request',
      'extend_months',
      'schedule_section.id',
      'schedule_section.phase',
      'schedule_section.priority',
      'status',
      'overwrite',
      'overwrite_time_period',
    ],
    async getFacilities() {
      return await $cms.request(
        $readItems('facility', {
          filter: {
            _and: [
              {
                status: {
                  _neq: 'ARCHIVED',
                },
              },
            ],
          },
          fields: [
            ...this.facilityFields,
            ...this.unitFields.map((field) => 'units.' + field),
            ...this.facilityFields.map((field) => 'units.facility.' + field),
          ],
          limit: -1,
          deep: {
            units: {
              _sort: ['short_name'],
            },
          },
          sort: ['name'],
        })
      )
    },
    async getUsers() {
      return await $cms.request(
        $readUsers({
          filter: {
            _and: [
              {
                status: {
                  _neq: 'archived',
                },
              },
              {
                role: {
                  identifier: {
                    _eq: 'ROTATING_STAFF',
                  },
                },
              },
            ],
          },
          fields: [...this.userFields],
          limit: -1,
          sort: ['last_name'],
        })
      )
    },
    async getTimeSpans(filter) {
      return await $cms.request(
        $readItems('time_period', {
          filter,
          fields: [...this.timeSpanFields, ...this.userFields.map((field) => 'user.' + field)],
          limit: -1,
          sort: ['-end'],
        })
      )
    },
    async getEntries(filter) {
      return await $cms.request(
        $readItems('schedule_history', {
          filter,
          fields: [
            ...this.entryFields,
            ...this.userFields.map((field) => 'user.' + field),
            ...this.unitFields.map((field) => 'unit.' + field),
            ...this.facilityFields.map((field) => 'unit.facility.' + field),
          ],
          limit: -1,
          sort: ['-end'],
        })
      )
    },
    async getDataForTime(start, end) {
      const facilities = await this.getFacilities()
      const users = await this.getUsers()
      const timeSpans = await this.getTimeSpans({
        _and: [
          {
            end: {
              _gte: start,
            },
          },
          {
            start: {
              _lte: end,
            },
          },
        ],
      })
      const extraEntries = await this.getEntries({
        _and: [
          {
            end: {
              _gte: start,
            },
          },
          {
            start: {
              _lte: end,
            },
          },
          {
            status: {
              _eq: 'confirmed',
            },
          },
          {
            schedule_suggestion_run: {
              _null: true,
            },
          },
        ],
      })
      return { facilities, users, timeSpans, extraEntries }
    },
    async getDataForSuggestionRun(suggestionRun) {
      return await this.getDataForTime(suggestionRun.start, suggestionRun.end)
    },
    hasUnassignedUsers(rotationCycles) {
      return rotationCycles.some(
        (cycle) =>
          !cycle.unassignedUsers ||
          cycle.unassignedUsers.some(
            (user) =>
              (!user.timeSpans || !user.timeSpans.length) &&
              (!user.extraEntries || !user.extraEntries.length)
          )
      )
    },
    hasUnitsBelowMinimumOccupancy(rotationCycles) {
      return rotationCycles.some((cycle) =>
        cycle.rotationTypes.some((rotationType) =>
          rotationType.units.some((unit) => unit.isBelowMinimumOccupancy)
        )
      )
    },
  }

  return {
    provide: {
      history: historyHelpers,
    },
  }
})
