import { App } from '@capacitor/app'
export default defineNuxtPlugin((app) => {
  onNuxtReady(async () => {
    const router = useRouter()
    App.addListener('appUrlOpen', function (event) {
      const slug = event.url.split('.de').pop()
      if (slug) {
        router.push({
          path: slug,
        })
      }
    })
  })
})
