import { useMainStore } from '~/store'
import { useFacilityStore } from '~/store/facility'
import { useNotificationStore } from '~/store/notification'
import { useSidebarStore } from '~/store/sidebar'

export default defineNuxtPlugin(({ $pinia }) => {
  return {
    provide: {
      store: useMainStore($pinia),
      facilityStore: useFacilityStore($pinia),
      notificationStore: useNotificationStore($pinia),
      sidebarStore: useSidebarStore($pinia),
    },
  }
})
