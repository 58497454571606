import revive_payload_client_9IFdZesYPJ from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import path_client_2CrkUkjKpA from "/app/plugins/path.client.ts";
import unhead_r7x6QHOCEM from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nzTz8fq0Y9 from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Lkz5KhltaC from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bL0jNk1aGc from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QhjV249I7Z from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EU7o1MphTo from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CgAO7h9vg4 from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.3_typescript@4.9.5_vue@3.5.4_typescript@4.9.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_pEZGiwJOYV from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import deeplinks_CV6iFr87A8 from "/app/plugins/deeplinks.js";
import directus_BNSudx5Icl from "/app/plugins/directus.js";
import error_handler_VFH3VvK7yG from "/app/plugins/error-handler.js";
import global_mixins_FeoDNy7nGB from "/app/plugins/global-mixins.js";
import history_helpers_JIsi3y9CQn from "/app/plugins/history-helpers.js";
import mitt_ZNXaeqsgM5 from "/app/plugins/mitt.js";
import pinia_48xmdi2HHl from "/app/plugins/pinia.ts";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import vue_fullscreen_32nZ9CzZkY from "/app/plugins/vue-fullscreen.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_9IFdZesYPJ,
  path_client_2CrkUkjKpA,
  unhead_r7x6QHOCEM,
  router_nzTz8fq0Y9,
  payload_client_Lkz5KhltaC,
  navigation_repaint_client_bL0jNk1aGc,
  check_outdated_build_client_QhjV249I7Z,
  chunk_reload_client_EU7o1MphTo,
  plugin_vue3_CgAO7h9vg4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pEZGiwJOYV,
  deeplinks_CV6iFr87A8,
  directus_BNSudx5Icl,
  error_handler_VFH3VvK7yG,
  global_mixins_FeoDNy7nGB,
  history_helpers_JIsi3y9CQn,
  mitt_ZNXaeqsgM5,
  pinia_48xmdi2HHl,
  sentry_client_GoGQuZo4Et,
  vue_fullscreen_32nZ9CzZkY,
  vuetify_7h9QAQEssH
]