import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alert', {
  state: () => ({
    onPage: [],
    open: null,
    isTour: false,
  }),
  actions: {
    add(alert) {
      this.onPage.push(alert)
    },
  },
})
