import DOMPurify from 'dompurify'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { isBefore, isAfter, format, parseISO, isEqual } from 'date-fns'
import deLocale from 'date-fns/locale/de'

export default defineNuxtPlugin((app) => {
  if (!app.vueApp.__my_mixin__) {
    app.vueApp.__my_mixin__ = true
    app.vueApp.mixin({
      computed: {
        defaultRotationTypes() {
          return app.$history.defaultRotationTypes
        },
        currentUser() {
          return app.$store.user
        },
        isNativePlatform() {
          return Capacitor.isNativePlatform()
        },
        selectedFacility() {
          return app.$facilityStore.id
        },
      },
      methods: {
        async login(email, password, otp) {
          localStorage.clear()
          const options = {}
          if (otp) {
            options.otp = otp
          }
          await app.$cms.login(email, password, options)
          if (Capacitor.isNativePlatform()) {
            PushNotifications.requestPermissions().then((result) => {
              if (result.receive === 'granted') {
                PushNotifications.register()
              }
            })

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration', (token) => {
              this.addFcmToken(token.value)
            })
          } else {
            try {
              // Initialize Firebase
              const firebaseApp = initializeApp(app.$config.public.firebaseConfig)
              const messaging = getMessaging(firebaseApp)
              getToken(messaging, {
                vapidKey: app.$config.public.vapidKey,
              })
                .then((currentToken) => {
                  if (currentToken) {
                    this.addFcmToken(currentToken)
                  }
                })
                .catch((error) => {
                  console.log('Could not get firebase token:', error)
                })
            } catch (reason) {
              console.log('Could not get firebase token:', reason)
            }
          }
        },
        useLocalStorage(key) {
          const version = '1'
          const savedVersion = localStorage.getItem('localStorageVersion')
          if (version !== savedVersion) {
            const items = { ...localStorage }
            Object.keys(items).forEach((key) => {
              if (key.startsWith('/')) {
                localStorage.removeItem(key)
              }
            })
            localStorage.setItem('localStorageVersion', version)
          }

          const path = this.$route.path + '/'
          const savedValue = localStorage.getItem(path + key)
          if (savedValue) {
            this[key] = JSON.parse(savedValue)
          }
          this.$watch(
            key,
            (newValue) => {
              localStorage.setItem(path + key, JSON.stringify(newValue))
            },
            { deep: true }
          )
        },
        async useDatabaseStorage(key, date = null, validateFunction = null) {
          try {
            await this.retrieveUserStorageFromDatabase(key, date, validateFunction)
          } catch (error) {
            console.error('Error loading user data from database:', error)
          }

          this.$watch(
            key,
            async (newValue) => {
              try {
                await this.saveUserStorageToDatabase(key, newValue, date)
              } catch (error) {
                console.error('Error saving user data to database:', error)
              }
            },
            { deep: true }
          )
        },
        async retrieveUserStorageFromDatabase(key, date, validateFunction) {
          try {
            const response = await this.$cms.request(
              this.$readItems('user_data_storage', {
                filter: {
                  user: {
                    _eq: this.currentUser.id,
                  },
                  path: {
                    _eq: this.$route.fullPath,
                  },
                  key: {
                    _eq: key,
                  },
                  _or: [
                    {
                      valid_until: {
                        _gte: new Date(),
                      },
                    },
                    {
                      valid_until: {
                        _null: true,
                      },
                    },
                  ],
                },
                fields: ['id', 'key', 'value'],
              })
            )
            if (response.length > 0) {
              const value = JSON.parse(response[0].value)
              if (!validateFunction || validateFunction(value)) {
                this[key] = value
              }
            }
          } catch (error) {
            console.error('Error retrieving user storage from database:', error)
          }
        },
        async saveUserStorageToDatabase(key, value, date) {
          const existingRecords = await this.$cms.request(
            this.$readItems('user_data_storage', {
              filter: {
                key: { _eq: key },
                user: { _eq: this.currentUser.id },
                path: { _eq: this.$route.fullPath },
              },
            })
          )
          try {
            const payload = {
              key,
              value: JSON.stringify(value),
              user: this.currentUser.id,
              valid_until: date,
              path: this.$route.fullPath,
            }

            if (existingRecords && existingRecords.length > 0) {
              const recordId = existingRecords[0].id
              await this.$cms.request(this.$updateItem('user_data_storage', recordId, payload))
            } else {
              await this.$cms.request(this.$createItem('user_data_storage', payload))
            }
          } catch (error) {
            console.error('Error saving user data to database:', error)
          }
        },
        async getUnitTypes(facilityId, withDefault) {
          let filterObject = {}
          if (facilityId) {
            filterObject = {
              filter: {
                facility: {
                  _eq: facilityId,
                },
              },
            }
          }

          const response = await app.$cms.request(
            app.$readItems('unit', {
              ...filterObject,
              fields: ['id', 'rotation_types'],
              sort: ['rotation_types'],
            })
          )
          const unitRotationTypes = response.map((unit) => unit.rotation_types)
          const rotationTypes = unitRotationTypes.flat()
          if (withDefault) {
            rotationTypes.push(...this.defaultRotationTypes)
          }
          return [...new Set(rotationTypes)].filter((rotationType) => rotationType)
        },
        async addFcmToken(token) {
          const user = await app.$cms.request(
            app.$readMe({
              fields: ['id'],
            })
          )
          const existingToken = await app.$cms.request(
            app.$readItems('fcm_token', {
              filter: {
                _and: [
                  {
                    token: {
                      _eq: token,
                    },
                  },
                  {
                    user: {
                      _eq: user.id,
                    },
                  },
                ],
              },
              fields: ['id', 'token'],
            })
          )
          if (!existingToken.length) {
            app.$cms.request(
              app.$createItem('fcm_token', {
                user: user.id,
                token,
              })
            )
          }
        },
        hasRole(roles) {
          if (app.$store.user && app.$store.user.role && app.$store.user.role.identifier) {
            const currentRoleIdentifier = app.$store.user.role.identifier
            return roles.includes(currentRoleIdentifier)
          }
          return false
        },
        parseDate(dateString) {
          if (dateString.length === 10) {
            return parseISO(dateString + 'T00:00:00.000Z')
          } else {
            return new Date(dateString)
          }
        },
        formatDate(date, dateFormat) {
          if (date) {
            return format(this.parseDate(date), dateFormat || 'dd.MM.yyyy')
          }
        },
        formatDateYear(date) {
          if (date) {
            return format(new Date(date), ' yy')
          }
        },
        formatDateMonth(date) {
          if (date) {
            return format(new Date(date), 'dd. MMM', { locale: deLocale })
          }
        },
        formatTimeSpan(start, end) {
          if (start) {
            if (end && end !== '2099-12-31') {
              return `${format(this.parseDate(start), 'dd.MM.yyyy')} - ${format(
                this.parseDate(end),
                'dd.MM.yyyy'
              )}`
            } else {
              return `ab ${format(this.parseDate(start), 'dd.MM.yyyy')}`
            }
          }
        },
        formatTime(date) {
          if (date) {
            return format(new Date(date), 'HH:mm')
          }
        },
        formatGender(gender) {
          if (gender) {
            switch (gender.toLowerCase()) {
              case 'female':
                return 'Frau'
              case 'male':
                return 'Herr'
              default:
                return ''
            }
          }
          return ''
        },
        formatCurrency(amount) {
          // Create our number formatter.
          const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',

            // These options are needed to round to whole numbers if that's what you want.
            // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          })

          return formatter.format(amount) /* $2,500.00 */
        },
        iconForCareType(type) {
          switch (type) {
            case 'INPATIENT':
              return 'mdi-bed'
            case 'OUTPATIENT':
              return 'mdi-ambulance'
            default:
              return 'fas fa-fw fa-question-circle'
          }
        },
        formatCareType(type, noContent = '-') {
          switch (type) {
            case 'INPATIENT':
              return 'stationär'
            case 'OUTPATIENT':
              return 'ambulant'
            default:
              return noContent
          }
        },
        formatFacilityType(type) {
          switch (type) {
            case 'INPATIENT':
              return 'Klinik / Abteilung (stationär)'
            case 'OUTPATIENT':
              return 'Praxis (ambulant)'
            default:
              return '-'
          }
        },
        getQueryParams(options) {
          const directusOptions = {
            limit: options.itemsPerPage ? options.itemsPerPage : 10,
            page: options.page ? options.page : 1,
            // meta: 'filter_count',
          }
          if (options.sortBy && options.sortBy.length && options.sortBy[0].key) {
            directusOptions.sort = options.sortBy.map((sort) => {
              return (sort.order === 'desc' ? '-' : '') + sort.key
            })
          }
          return directusOptions
        },
        formatUserName(user, showMail = true, showTitle = true) {
          let name = ''
          if (user) {
            if (user.first_name || user.last_name) {
              if (user.gender && showTitle) {
                switch (user.gender) {
                  case 'FEMALE':
                    name = 'Frau '
                    break
                  case 'MALE':
                    name = 'Herr '
                    break
                }
              }
              if (user.title && showTitle) {
                name = name + user.title + ' '
              }
              return name + `${user.first_name} ${user.last_name}`
            } else if (showMail) {
              return user.email
            }
          }
          return ''
        },
        getConfig(key) {
          return this.currentUser.configurations.find((config) => config.key === key)
        },
        async setConfig(key, value, additionalValue = null) {
          const config = this.getConfig(key)
          const user = JSON.parse(JSON.stringify(this.currentUser))
          if (config) {
            const updatedConfig = await app.$cms.request(
              app.$updateItem(
                'configuration',
                config.id,
                {
                  value,
                  additional_value: additionalValue,
                },
                {
                  fields: ['id', 'key', 'value', 'additional_value'],
                }
              )
            )
            user.configurations = user.configurations.filter(
              (existingConfig) => existingConfig.id !== config.id
            )
            user.configurations.push(updatedConfig)
          } else {
            const newConfig = await app.$cms.request(
              app.$createItem(
                'configuration',
                {
                  user: user.id,
                  key,
                  value,
                  additional_value: additionalValue,
                },
                {
                  fields: ['id', 'key', 'value', 'additional_value'],
                }
              )
            )
            user.configurations.push(newConfig)
          }
          app.$store.user = user
        },
        getFilterForVacancies(filter) {
          let _or = []
          if (filter.speciality) {
            _or = filter.speciality.map((speciality) => {
              const _and = [
                {
                  speciality: {
                    _eq: speciality.id,
                  },
                },
              ]
              if (speciality.type) {
                _and.push({
                  facility: {
                    type: {
                      _eq: speciality.type,
                    },
                  },
                })
              }
              if (
                filter.matchRequiredSpecialist &&
                speciality.requiredSpecialist &&
                speciality.requiredSpecialist.length
              ) {
                _and.push({
                  user: {
                    physician: {
                      target_speciality: {
                        _in: speciality.requiredSpecialist,
                      },
                    },
                  },
                })
              }
              return {
                _and,
              }
            })
          }
          const _and = []
          if (_or.length) {
            _and.push({ _or })
          }
          if (filter.startAfter) {
            _and.push({
              _or: [
                {
                  end: {
                    _gte: filter.startAfter,
                  },
                },
                {
                  end: {
                    _null: true,
                  },
                },
              ],
            })
          }
          if (filter.status && filter.status.length) {
            _and.push({
              status: {
                _in: filter.status,
              },
            })
          }
          if (filter.facilities && filter.facilities.length) {
            _and.push({
              facility: {
                _in: filter.facilities,
              },
            })
          }
          if (filter.parttime !== filter.fulltime) {
            if (filter.parttime) {
              _and.push({
                parttime: {
                  _eq: true,
                },
              })
            }
            if (filter.fulltime) {
              _and.push({
                fulltime: {
                  _eq: true,
                },
              })
            }
          }
          return { _and }
        },
        dompurify(html) {
          return DOMPurify.sanitize(html)
        },
        async getPhysician(userId, fields = ['id']) {
          const physicians = await app.$cms.request(
            app.$readItems('physician', {
              filter: {
                user: {
                  _eq: userId,
                },
              },
              fields,
              limit: 1,
            })
          )
          if (physicians.length) {
            return physicians[0]
          } else {
            return await app.$cms.request(
              app.$createItem('physician', { user: userId }, { fields })
            )
          }
        },
        async fileUrl(id) {
          if (id) {
            const token = await app.$cms.getToken()
            return `${app.$config.public.cmsUrl}/assets/${id}?access_token=${token}`
          } else {
            return null
          }
        },
        formatParts(parts, separator = ' - ', lastSeparator = separator) {
          const strings = parts.filter((part) => part && part.length)
          if (!strings.length) {
            return ''
          } else if (strings.length === 1) {
            return strings[0]
          } else {
            return strings.slice(0, -1).join(separator) + lastSeparator + strings.slice(-1)
          }
        },
        getColorForTime(entry) {
          if (entry.overwrite) {
            return 'error'
          }
          if (entry.status && entry.status !== 'confirmed') {
            return 'secondary'
          }
          const now = new Date()
          const start = this.parseDate(entry.start)
          const end = this.parseDate(entry.end)
          if (isBefore(end, now)) {
            return 'primary'
          } else if (isAfter(start, now)) {
            return 'accent'
          } else {
            return 'success'
          }
        },
        getStatusForTime(entry) {
          const now = new Date()
          const start = this.parseDate(entry.start)
          const end = this.parseDate(entry.end || '2099-12-31')
          if (isBefore(end, now)) {
            return 'past'
          } else if (isAfter(start, now)) {
            return 'future'
          } else {
            return 'current'
          }
        },
        isOwnFacility(facilityId) {
          if (this.hasRole(['ADMINISTRATIVE_STAFF'])) {
            return this.currentUser.facilities
              .filter((facility) => facility.type === 'default')
              .map((facility) => facility.facility_id.id)
              .includes(facilityId)
          }
          return true
        },
        getPremiumFeatures() {
          if (this.currentUser.main_facility && this.currentUser.main_facility.premium_features) {
            return this.currentUser.main_facility.premium_features
          } else {
            return []
          }
        },
        async deleteUser(userId) {
          const physician = await this.getPhysician(userId, ['id', 'documents.directus_files_id'])
          const user = await app.$cms.request(app.$readUser(userId, { fields: ['id', 'avatar'] }))
          if (physician.documents.length) {
            const listFiles = physician.documents.map((element) => element.directus_files_id)
            await app.$cms.request(app.$deleteItems('directus_files', listFiles))
          }
          if (user.avatar) {
            await app.$cms.request(app.$deleteItem('directus_files', user.avatar))
          }
          await app.$cms.request(app.$deleteUser(userId))
        },
        groupByProperty(objects, propertyName, multiple = false) {
          return objects.reduce(function (r, a) {
            if (multiple) {
              if (a[propertyName]) {
                a[propertyName].forEach((value) => {
                  r[value] = r[value] || []
                  r[value].push(a)
                })
              }
              return r
            } else {
              r[a[propertyName]] = r[a[propertyName]] || []
              r[a[propertyName]].push(a)
              return r
            }
          }, Object.create(null))
        },
        getColorForPercentage(value, max) {
          if (value && max) {
            const percent = value / max
            if (percent <= 0.25) {
              return 'yellow'
            } else if (percent <= 0.5) {
              return 'lime'
            } else if (percent <= 0.75) {
              return 'light-green'
            } else {
              return 'green'
            }
          }
          return 'grey'
        },
        formatFacilityHierarchy(facility, separator = ' > ') {
          if (!facility) {
            return ''
          }
          if (facility.parent_facility === null) {
            return facility.name
          }
          let currentFacility = JSON.parse(JSON.stringify(facility))
          const facilities = []
          while (currentFacility.parent_facility) {
            if (currentFacility.parent_facility.show_in_hierarchy) {
              facilities.unshift(currentFacility.parent_facility)
            }
            currentFacility = currentFacility.parent_facility
          }
          return this.formatParts(
            facilities.map((f) => f?.name),
            separator
          )
        },
        getRequirementTitle(requirement, rindex) {
          let text = `${rindex && rindex > 0 ? 'und ' : ''}`
          if (requirement.minimum_months) {
            text = text + requirement.minimum_months + ' M. '
          }
          if (
            requirement.units?.length ||
            requirement.specialities?.length ||
            requirement.rotation_types?.length ||
            requirement.facilities?.length
          ) {
            text =
              text +
              `${this.formatParts(
                [
                  this.getNames(requirement.units, 'unit_id.short_name', 'Einheiten'),
                  this.getNames(requirement.specialities, 'speciality_id.name', 'Fachrichtungen'),
                  requirement.rotation_types?.length ? requirement.rotation_types.join(', ') : null,
                  this.getNames(requirement.facilities, 'facility_id.name', 'Kliniken'),
                ],
                ', '
              )}`
          } else {
            text = text + 'Erfahrung jeglicher Art'
          }
          if (requirement.label) {
            return 'Label: ' + requirement.label.name
          } else if (requirement.minimum_duration) {
            return 'Mindestaufenthalt: ' + requirement.minimum_duration + ' M.'
          } else if (requirement.minimum_percentage) {
            return 'Arbeitsmodell: ' + requirement.minimum_percentage + '%'
          }

          return text
        },
        getNames(array, path, pluralName = '', maxlength = 3) {
          if (!array || !array.length) {
            return null
          } else if (array.length <= maxlength) {
            return this.formatParts(
              array.map((object) => this.getValueByPath(object, path)),
              ', ',
              ' oder '
            )
          } else {
            return array.length + ' ' + pluralName
          }
        },
        isInTime(object, startDate, endDate, inc = true) {
          const objectStart = this.parseDate(object.start)
          const objectEnd = this.parseDate(object.end)
          const start = this.parseDate(startDate)
          const end = this.parseDate(endDate)
          const before = isBefore(objectStart, end) || (inc && isEqual(objectStart, end))
          const after = isAfter(objectEnd, start) || (inc && isEqual(objectEnd, start))
          return before && after
        },
        getValueByPath(object, path) {
          let o = object
          if (path) {
            path = path.replace(/\[(\w+)\]/g, '.$1')
            path = path.replace(/^\./, '')
            const a = path.split('.')
            while (a.length) {
              const n = a.shift()
              if (!o || (typeof o === Object && !(n in o))) return null
              o = o[n]
            }
          }
          return o
        },
        setValueByPath(obj, path, value) {
          // Split the path into an array of keys
          const keys = path.split('.')

          // Iterate over the keys array, except for the last key
          for (let i = 0; i < keys.length - 1; i++) {
            let key = keys[i]

            // Check if the current key is an array index
            if (!isNaN(parseInt(key))) {
              key = parseInt(key) // Convert to a number if it's an index
            }

            // If the key doesn't exist or isn't an object/array, create an empty object at this key
            if (!obj[key] || typeof obj[key] !== 'object') {
              obj[key] = isNaN(parseInt(keys[i + 1])) ? {} : [] // Use an array if the next key is a numeric index
            }

            // Move to the next level in the object
            obj = obj[key]
          }

          // Set the value at the final key
          const finalKey = keys[keys.length - 1]
          obj[isNaN(parseInt(finalKey)) ? finalKey : parseInt(finalKey)] = value
        },
        async subscribeToRealTime(collection, events, query, callback) {
          try {
            await this.$cms.onWebSocket('message', async (data) => {
              if (data.type == 'auth' && data.status == 'ok') {
                events.forEach(async (event) => {
                  try {
                    const { subscription } = await this.$cms.subscribe(collection, {
                      event,
                      query,
                    })
                    for await (const notification of subscription) {
                      callback(notification)
                    }
                  } catch (error) {
                    console.error('Error subscribing to event:', error)
                  }
                })
              }
            })
          } catch (error) {
            console.error('WebSocket connection error:', error)
          }
        },
        getReasonTitle(reason) {
          switch (reason) {
            case 'flow_rotation':
              return 'Im Rotationscurriculum erreichbar'
            case 'flow_rotation_progress':
              return 'Fortgeschrittene Position im Rotationscurriculum'
            case 'flow_rotation_prio':
              return 'Priorität im Rotationscurriculum'
            case 'flow_requirement':
              return 'Benötigt für Weiterkommen im Rotationscurriculum'
            case 'regulation':
              return 'Benötigt laut Weiterbildungsordnung'
            case 'goals':
              return 'Benötigt für Ziele'
            case 'request':
              return 'Wunscheinheit des Rotanden'
            case 'request_history':
              return 'Abzug für bereits erfüllten Wunsch'
            case 'last_unit':
              return 'Dauer seit letztem Mal in Einheit'
            case 'last_facility':
              return 'Dauer seit letztem Mal in Klinik'
            case 'external':
              return 'Priorität Rotation gegenüber Einsatzplanung'
            case 'last_external':
              return 'Dauer seit letztem Mal in Rotation'
            case 'external_request':
              return 'Wunschklinik des Rotanden'
            case 'new_competence':
              return 'Neu erlernte Kompetenzen'
            case 'extension':
              return 'Mindestaufenthaltsdauer'
            default:
              return 'Sonstige'
          }
        },
        getShortName(facility) {
          if (!facility) {
            // Garbage in, garbage out, what are we supposed to do here?
            return facility
          }

          if (typeof facility === 'object' && 'shortName' in facility && facility.shortName) {
            return facility.shortName
          }

          if (typeof facility === 'object' && 'short_name' in facility && facility.short_name) {
            return facility.short_name
          }

          const longName =
            typeof facility === 'string' || facility instanceof String ? facility : facility.name

          return longName
            .replaceAll(/(?<=[\p{Letter}])[\p{Lowercase_Letter}]/gu, '.')
            .replaceAll(/\.+/g, '.')
        },
        shortenEmail(email, maxLength = 25) {
          if (!email) return ''
          const [localPart] = email.split('@')
          if (localPart.length < maxLength) return email
          return `${localPart.slice(0, maxLength)}@...`
        },
        async truncateName(name, checkEllipsis) {
          const words = name.split(' ')
          // Handle four words
          if (words.length === 4) {
            const firstName = words[0]
            const middleName1 = words[1]
            const middleName2 = words[2]
            const lastName = words[3]

            // Try full name first
            let truncated = name
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }

            // Try with first and middle initials
            truncated = `${firstName} ${middleName1[0]}. ${middleName2[0]}. ${lastName}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }

            // Try with first name initial only
            truncated = `${firstName[0]}. ${middleName1[0]}. ${middleName2[0]}. ${lastName}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }
          }
          // Handle three words
          if (words.length === 3) {
            // Try full name first
            let truncated = name
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }

            const firstName = words[0]
            const middleName = words[1]
            const lastName = words[words.length - 1]

            //Try with middle initial
            const middleInitial = middleName[0]
            truncated = `${firstName} ${middleInitial}. ${lastName}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }

            //Try with first name initial
            truncated = `${firstName[0]}. ${middleInitial}. ${lastName}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }
          }

          // Handle two words
          if (words.length === 2) {
            const firstWord = words[0]
            const lastWord = words[1]

            let truncated = `${firstWord} ${lastWord}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }

            truncated = `${firstWord[0]}. ${lastWord}`
            if (!(await checkEllipsis(truncated))) {
              return truncated
            }
          }
          if (words.length === 1) {
            return words[0]
          }

          // If still too long return initials
          return words.map((word) => word[0]).join('. ') + '.'
        },
        isEllipsisActive(el) {
          if (el) {
            return el.offsetWidth < el.scrollWidth
          }
          return false
        },
      },
    })
  }
})
