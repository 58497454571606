import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    user: {},
    realTimeId: null,
  }),
  actions: {
    setUserContact(user) {
      this.user.gender = user.gender
      this.user.title = user.title
      this.user.first_name = user.first_name
      this.user.last_name = user.last_name
      this.user.email = user.email
    },
    setTfa(tfaSecret) {
      this.user.tfa_secret = tfaSecret
    },
  },
})
