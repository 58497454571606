import validate from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45alerts_45global from "/app/middleware/resetAlerts.global.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2vyt7ocwx6xku2qrr364ykhxv4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  reset_45alerts_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/app/middleware/authenticated.js")
}