import { default as congrats9SXSwt6rs1Meta } from "/app/pages/administrative/congrats.vue?macro=true";
import { default as fillprofilezEl0h6gUBGMeta } from "/app/pages/auth/fillprofile.vue?macro=true";
import { default as forwardzX7QWJrgkxMeta } from "/app/pages/auth/forward.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as logoutCHyXH6UWwkMeta } from "/app/pages/auth/logout.vue?macro=true";
import { default as setemail7N0uoIyXKSMeta } from "/app/pages/auth/setemail.vue?macro=true";
import { default as _91token_932rKXFMjh9jMeta } from "/app/pages/auth/setpassword/[token].vue?macro=true";
import { default as indexzSlyrIMCyIMeta } from "/app/pages/auth/setpassword/index.vue?macro=true";
import { default as terminationG7h6rzAaoDMeta } from "/app/pages/auth/termination.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as administrativeic5LhdqB0BMeta } from "/app/pages/dashboard/administrative.vue?macro=true";
import { default as externalbhvveCtHwDMeta } from "/app/pages/dashboard/external.vue?macro=true";
import { default as rotatingx54MPYAcIPMeta } from "/app/pages/dashboard/rotating.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as overviewVQPJb7PI9lMeta } from "/app/pages/licenses/overview.vue?macro=true";
import { default as administrativeUCjbkwY2TrMeta } from "/app/pages/network/administrative.vue?macro=true";
import { default as organisationLmFig2KCBfMeta } from "/app/pages/network/organisation.vue?macro=true";
import { default as _91id_936bZZa6UFNWMeta } from "/app/pages/network/profile/administrative/[id].vue?macro=true";
import { default as rotatingBKBP4zKM9uMeta } from "/app/pages/network/rotating.vue?macro=true";
import { default as _91id_93rNDXQGkV1FMeta } from "/app/pages/network/user/[id].vue?macro=true";
import { default as _91id_938JtzLyopBQMeta } from "/app/pages/news/details/[id].vue?macro=true";
import { default as listOUBWM57TDlMeta } from "/app/pages/news/list.vue?macro=true";
import { default as administrativezSnaDPSJDZMeta } from "/app/pages/onboarding/administrative.vue?macro=true";
import { default as finish6XFGAORxUlMeta } from "/app/pages/onboarding/finish.vue?macro=true";
import { default as forwardv8yiFelIq9Meta } from "/app/pages/onboarding/forward.vue?macro=true";
import { default as rotatingaAJrDGOsOMMeta } from "/app/pages/onboarding/rotating.vue?macro=true";
import { default as contactV6vhwRZQ6xMeta } from "/app/pages/profile/contact.vue?macro=true";
import { default as documentsmdPwT6rF7zMeta } from "/app/pages/profile/documents.vue?macro=true";
import { default as overviewINuivfGyzsMeta } from "/app/pages/profile/overview.vue?macro=true";
import { default as payments3sCVlIOHuAMeta } from "/app/pages/profile/payments.vue?macro=true";
import { default as regulationCui9OqRTPCMeta } from "/app/pages/profile/regulation.vue?macro=true";
import { default as timeperiodsKiMPm7IYTdMeta } from "/app/pages/profile/timeperiods.vue?macro=true";
import { default as coordinator8eGn33mG9WMeta } from "/app/pages/rotationplan/coordinator.vue?macro=true";
import { default as externalqXcgavzvyDMeta } from "/app/pages/rotationplan/external.vue?macro=true";
import { default as externalslotst7WUpiQjeuMeta } from "/app/pages/rotationplan/externalslots.vue?macro=true";
import { default as planTESyluUKFvMeta } from "/app/pages/rotationplan/plan.vue?macro=true";
import { default as planningd3Qax431NOMeta } from "/app/pages/rotationplan/planning.vue?macro=true";
import { default as printplaneI7B9wMsqpMeta } from "/app/pages/rotationplan/printplan.vue?macro=true";
import { default as startw4zRqP284BMeta } from "/app/pages/rotationplan/start.vue?macro=true";
import { default as approvalspA3vwEKDQQMeta } from "/app/pages/schedule/approvals.vue?macro=true";
import { default as _91id_93iFKGsHfke1Meta } from "/app/pages/schedule/edit/[id].vue?macro=true";
import { default as _91facilityId_93ZTfoEZOWtYMeta } from "/app/pages/schedule/flow/[facilityId].vue?macro=true";
import { default as printflow0swZiKiElbMeta } from "/app/pages/schedule/flow/printflow.vue?macro=true";
import { default as historyOM9WCeXZsSMeta } from "/app/pages/schedule/history.vue?macro=true";
import { default as listG3jJhpFnoOMeta } from "/app/pages/schedule/list.vue?macro=true";
import { default as requestsE3qM83lMYPMeta } from "/app/pages/schedule/requests.vue?macro=true";
export default [
  {
    name: "administrative-congrats",
    path: "/administrative/congrats",
    meta: congrats9SXSwt6rs1Meta || {},
    component: () => import("/app/pages/administrative/congrats.vue").then(m => m.default || m)
  },
  {
    name: "auth-fillprofile",
    path: "/auth/fillprofile",
    meta: fillprofilezEl0h6gUBGMeta || {},
    component: () => import("/app/pages/auth/fillprofile.vue").then(m => m.default || m)
  },
  {
    name: "auth-forward",
    path: "/auth/forward",
    meta: forwardzX7QWJrgkxMeta || {},
    component: () => import("/app/pages/auth/forward.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-setemail",
    path: "/auth/setemail",
    meta: setemail7N0uoIyXKSMeta || {},
    component: () => import("/app/pages/auth/setemail.vue").then(m => m.default || m)
  },
  {
    name: "auth-setpassword-token",
    path: "/auth/setpassword/:token()",
    meta: _91token_932rKXFMjh9jMeta || {},
    component: () => import("/app/pages/auth/setpassword/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-setpassword",
    path: "/auth/setpassword",
    meta: indexzSlyrIMCyIMeta || {},
    component: () => import("/app/pages/auth/setpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-termination",
    path: "/auth/termination",
    meta: terminationG7h6rzAaoDMeta || {},
    component: () => import("/app/pages/auth/termination.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-administrative",
    path: "/dashboard/administrative",
    meta: administrativeic5LhdqB0BMeta || {},
    component: () => import("/app/pages/dashboard/administrative.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-external",
    path: "/dashboard/external",
    meta: externalbhvveCtHwDMeta || {},
    component: () => import("/app/pages/dashboard/external.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rotating",
    path: "/dashboard/rotating",
    meta: rotatingx54MPYAcIPMeta || {},
    component: () => import("/app/pages/dashboard/rotating.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "licenses-overview",
    path: "/licenses/overview",
    meta: overviewVQPJb7PI9lMeta || {},
    component: () => import("/app/pages/licenses/overview.vue").then(m => m.default || m)
  },
  {
    name: "network-administrative",
    path: "/network/administrative",
    meta: administrativeUCjbkwY2TrMeta || {},
    component: () => import("/app/pages/network/administrative.vue").then(m => m.default || m)
  },
  {
    name: "network-organisation",
    path: "/network/organisation",
    meta: organisationLmFig2KCBfMeta || {},
    component: () => import("/app/pages/network/organisation.vue").then(m => m.default || m)
  },
  {
    name: "network-profile-administrative-id",
    path: "/network/profile/administrative/:id()",
    meta: _91id_936bZZa6UFNWMeta || {},
    component: () => import("/app/pages/network/profile/administrative/[id].vue").then(m => m.default || m)
  },
  {
    name: "network-rotating",
    path: "/network/rotating",
    meta: rotatingBKBP4zKM9uMeta || {},
    component: () => import("/app/pages/network/rotating.vue").then(m => m.default || m)
  },
  {
    name: "network-user-id",
    path: "/network/user/:id()",
    meta: _91id_93rNDXQGkV1FMeta || {},
    component: () => import("/app/pages/network/user/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-details-id",
    path: "/news/details/:id()",
    meta: _91id_938JtzLyopBQMeta || {},
    component: () => import("/app/pages/news/details/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-list",
    path: "/news/list",
    meta: listOUBWM57TDlMeta || {},
    component: () => import("/app/pages/news/list.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-administrative",
    path: "/onboarding/administrative",
    meta: administrativezSnaDPSJDZMeta || {},
    component: () => import("/app/pages/onboarding/administrative.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-finish",
    path: "/onboarding/finish",
    meta: finish6XFGAORxUlMeta || {},
    component: () => import("/app/pages/onboarding/finish.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-forward",
    path: "/onboarding/forward",
    meta: forwardv8yiFelIq9Meta || {},
    component: () => import("/app/pages/onboarding/forward.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-rotating",
    path: "/onboarding/rotating",
    meta: rotatingaAJrDGOsOMMeta || {},
    component: () => import("/app/pages/onboarding/rotating.vue").then(m => m.default || m)
  },
  {
    name: "profile-contact",
    path: "/profile/contact",
    meta: contactV6vhwRZQ6xMeta || {},
    component: () => import("/app/pages/profile/contact.vue").then(m => m.default || m)
  },
  {
    name: "profile-documents",
    path: "/profile/documents",
    meta: documentsmdPwT6rF7zMeta || {},
    component: () => import("/app/pages/profile/documents.vue").then(m => m.default || m)
  },
  {
    name: "profile-overview",
    path: "/profile/overview",
    meta: overviewINuivfGyzsMeta || {},
    component: () => import("/app/pages/profile/overview.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: payments3sCVlIOHuAMeta || {},
    component: () => import("/app/pages/profile/payments.vue").then(m => m.default || m)
  },
  {
    name: "profile-regulation",
    path: "/profile/regulation",
    meta: regulationCui9OqRTPCMeta || {},
    component: () => import("/app/pages/profile/regulation.vue").then(m => m.default || m)
  },
  {
    name: "profile-timeperiods",
    path: "/profile/timeperiods",
    meta: timeperiodsKiMPm7IYTdMeta || {},
    component: () => import("/app/pages/profile/timeperiods.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-coordinator",
    path: "/rotationplan/coordinator",
    meta: coordinator8eGn33mG9WMeta || {},
    component: () => import("/app/pages/rotationplan/coordinator.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-external",
    path: "/rotationplan/external",
    meta: externalqXcgavzvyDMeta || {},
    component: () => import("/app/pages/rotationplan/external.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-externalslots",
    path: "/rotationplan/externalslots",
    meta: externalslotst7WUpiQjeuMeta || {},
    component: () => import("/app/pages/rotationplan/externalslots.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-plan",
    path: "/rotationplan/plan",
    meta: planTESyluUKFvMeta || {},
    component: () => import("/app/pages/rotationplan/plan.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-planning",
    path: "/rotationplan/planning",
    meta: planningd3Qax431NOMeta || {},
    component: () => import("/app/pages/rotationplan/planning.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-printplan",
    path: "/rotationplan/printplan",
    meta: printplaneI7B9wMsqpMeta || {},
    component: () => import("/app/pages/rotationplan/printplan.vue").then(m => m.default || m)
  },
  {
    name: "rotationplan-start",
    path: "/rotationplan/start",
    meta: startw4zRqP284BMeta || {},
    component: () => import("/app/pages/rotationplan/start.vue").then(m => m.default || m)
  },
  {
    name: "schedule-approvals",
    path: "/schedule/approvals",
    meta: approvalspA3vwEKDQQMeta || {},
    component: () => import("/app/pages/schedule/approvals.vue").then(m => m.default || m)
  },
  {
    name: "schedule-edit-id",
    path: "/schedule/edit/:id()",
    meta: _91id_93iFKGsHfke1Meta || {},
    component: () => import("/app/pages/schedule/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "schedule-flow-facilityId",
    path: "/schedule/flow/:facilityId()",
    meta: _91facilityId_93ZTfoEZOWtYMeta || {},
    component: () => import("/app/pages/schedule/flow/[facilityId].vue").then(m => m.default || m)
  },
  {
    name: "schedule-flow-printflow",
    path: "/schedule/flow/printflow",
    meta: printflow0swZiKiElbMeta || {},
    component: () => import("/app/pages/schedule/flow/printflow.vue").then(m => m.default || m)
  },
  {
    name: "schedule-history",
    path: "/schedule/history",
    meta: historyOM9WCeXZsSMeta || {},
    component: () => import("/app/pages/schedule/history.vue").then(m => m.default || m)
  },
  {
    name: "schedule-list",
    path: "/schedule/list",
    meta: listG3jJhpFnoOMeta || {},
    component: () => import("/app/pages/schedule/list.vue").then(m => m.default || m)
  },
  {
    name: "schedule-requests",
    path: "/schedule/requests",
    meta: requestsE3qM83lMYPMeta || {},
    component: () => import("/app/pages/schedule/requests.vue").then(m => m.default || m)
  }
]