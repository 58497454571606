export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (err) => {
    clearError()
    nuxtApp.$notificationStore.set({
      text: 'Es ist ein unerwarteter Fehler aufgetreten. Ein Administrator wurde benachrichtigt und wir arbeiten an der Behebung. Bitte versuchen Sie es später erneut.',
      type: 'error',
    })
    console.log('vueApp:errorHandler', err)
  }
  nuxtApp.hook('vue:error', (err) => {
    clearError()
    nuxtApp.$notificationStore.set({
      text: 'Es ist ein unerwarteter Fehler aufgetreten. Ein Administrator wurde benachrichtigt und wir arbeiten an der Behebung. Bitte versuchen Sie es später erneut.',
      type: 'error',
    })
    console.log('vue:error', err)
  })
  nuxtApp.hook('app:error', (err) => {
    clearError()
    nuxtApp.$notificationStore.set({
      text: 'Es ist ein unerwarteter Fehler aufgetreten. Ein Administrator wurde benachrichtigt und wir arbeiten an der Behebung. Bitte versuchen Sie es später erneut.',
      type: 'error',
    })
    console.log('app:error', err)
  })
})
