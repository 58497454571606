import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((app) => {
  const config = useRuntimeConfig()
  Sentry.init({
    enabled: config.public.environment !== 'development',
    app: app.vueApp,
    dsn: config.public.sentryDsn,
    release: config.public.appVersion,
    environment: config.public.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router: app.$router }),
      Sentry.replayIntegration(),
    ],
    trackComponents: true,
    normalizeDepth: 8,
    ignoreErrors: ['ResizeObserver'],

    // session events are not supported in glitchtip
    autoSessionTracking: false,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', config.public.baseUrl],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
